import type { TypeMote } from './balance';
export type TokenID = string | number;
export type PublicKey = string;
export interface EggForceConfigurationSpec {
  API_VERSION: string;
  ADMIN_ADDRESS: string; // DONE
  DRAGON_BROKER_CONTRACT_HASH: string; // DONE
  AUCTION_CONTRACT_HASH: string; // DONE
  BROKER_CONTRACT_HASH: string; // DONE
  INSTALLMENT_CONTRACT_HASH: string; // DONE
  TOKEN_CONTRACT_HASH: string; // DONE
  MAX_LEADERBOARD_RANK: number;
  MOTE_RATE: TypeMote;
  PAYMENT_COMMON: TypeMote;
  TOKEN_TRANSFER_FEE: TypeMote; // DONE
  STOP_ONLY_FEE: TypeMote; // DONE
  UPDATE_METADATA_FEE: TypeMote;
  SNC_TO_XP_RATIO: number;
}

export enum EggForceConfigurationEnum {
  API_VERSION = 'API_VERSION',
  ADMIN_ADDRESS = 'ADMIN_ADDRESS',
  DRAGON_BROKER_CONTRACT_HASH = 'DRAGON_BROKER_CONTRACT_HASH',
  AUCTION_CONTRACT_HASH = 'AUCTION_CONTRACT_HASH',
  BROKER_CONTRACT_HASH = 'BROKER_CONTRACT_HASH',
  INSTALLMENT_CONTRACT_HASH = 'INSTALLMENT_CONTRACT_HASH',
  TOKEN_CONTRACT_HASH = 'TOKEN_CONTRACT_HASH',
  MAX_LEADERBOARD_RANK = 'MAX_LEADERBOARD_RANK',
  MOTE_RATE = 'MOTE_RATE',
  PAYMENT_COMMON = 'PAYMENT_COMMON',
  TOKEN_TRANSFER_FEE = 'TOKEN_TRANSFER_FEE',
  STOP_ONLY_FEE = 'STOP_ONLY_FEE',
  UPDATE_METADATA_FEE = 'UPDATE_METADATA_FEE',
  SNC_TO_XP_RATIO = 'SNC_TO_XP_RATIO',
}

export type EggForceConfigurationKey = keyof EggForceConfigurationSpec;
